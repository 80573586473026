export const generators0 = [
  {
    id: "studio",
    altId: ["65d27bf92a92ae1578ca7bb7"],
    cfgU: 8.0,
    cfgM: 7.0,
    cfgL: 6.0,
    styleName: [
      { name: "en-US", value: "Studio" },
      { name: "ja-JP", value: "スタジオ " },
      { name: "ko-KR", value: "스튜디오" },
      { name: "zh-CN", value: "专业" },
      { name: "zh-TW", value: "專業" },
      { name: "es-MX", value: "Studio" },
      { name: "de-DE", value: "Studio" },
      { name: "fr-CA", value: "Estudio" },
      { name: "ru-RU", value: "Студия" },
      { name: "pt-BR", value: "Estúdio" },
    ],
    styleImage: "https://cdn6.imgpog.com/studio.webp",
  },
  // {
  //   id: "realistic", //"65036631adca1b7ac4084694",
  //   altId: ["65036631adca1b7ac4084694"],
  //   cfgU: 8.0,
  //   cfgM: 7.0,
  //   cfgL: 6.0,
  //   styleName: [
  //     { name: "en-US", value: "Porno" },
  //     { name: "ja-JP", value: "ポルノ" },
  //     { name: "ko-KR", value: "야동" },
  //     { name: "zh-CN", value: "欧美" },
  //     { name: "zh-TW", value: "歐美" },
  //     { name: "es-MX", value: "Porno" },
  //     { name: "de-DE", value: "Porno" },
  //     { name: "fr-CA", value: "Porno" },
  //     { name: "ru-RU", value: "Порно" },
  //     { name: "pt-BR", value: "Porno" },
  //   ],
  //   styleImage: "https://cdn6.imgpog.com/porno.webp",
  // },
  {
    id: "realisticphotography",
    altId: ["65983e0d838a5c28fbb3418d"],
    cfgU: 7.0,
    cfgM: 6.0,
    cfgL: 5.0,
    styleName: [
      { name: "en-US", value: "Amateur" },
      { name: "ja-JP", value: "オリジナル" },
      { name: "ko-KR", value: "원창작" },
      { name: "zh-CN", value: "原创" },
      { name: "zh-TW", value: "原創" },
      { name: "es-MX", value: "Aficionado" },
      { name: "de-DE", value: "Amateur" },
      { name: "fr-CA", value: "Amateur" },
      { name: "ru-RU", value: "Любительский" },
      { name: "pt-BR", value: "Amador" },
    ],
    styleImage: "https://cdn6.imgpog.com/amateur.webp",
  },
  {
    id: "porno",
    altId: ["6647da6269b831eea6ae756d"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Porno X" },
      { name: "ja-JP", value: "ポルノX" },
      { name: "ko-KR", value: "야동 X" },
      { name: "zh-CN", value: "欧美 X" },
      { name: "zh-TW", value: "歐美 X" },
      { name: "es-MX", value: "Porno X" },
      { name: "de-DE", value: "Porno X" },
      { name: "fr-CA", value: "Porno X" },
      { name: "ru-RU", value: "порно X" },
      { name: "pt-BR", value: "Porno X" },
    ],
    styleImage: "https://cdn6.imgpog.com/pornoxl.webp",
  },
  {
    id: "hyperrealism", //"64dc7ee1e59dc9c994281b08",
    altId: ["64dc7ee1e59dc9c994281b08"],
    cfgU: 12.0,
    cfgM: 7.0,
    cfgL: 5.0,
    styleName: [
      { name: "en-US", value: "Artistic" },
      { name: "ja-JP", value: "芸術" },
      { name: "ko-KR", value: "예술적인" },
      { name: "zh-CN", value: "艺术" },
      { name: "zh-TW", value: "藝術" },
      { name: "es-MX", value: "Artístico" },
      { name: "de-DE", value: "Künstlerisch" },
      { name: "fr-CA", value: "Artistique" },
      { name: "ru-RU", value: "Артистичный" },
      { name: "pt-BR", value: "Artístico" },
    ],
    styleImage: "https://cdn6.imgpog.com/artistic.webp",
  },
  {
    id: "realisticasian",
    altId: ["65983f13838a5c28fbb37836"],
    cfgU: 8.0,
    cfgM: 6.5,
    cfgL: 5.0,
    styleName: [
      { name: "en-US", value: "JAV" },
      { name: "ja-JP", value: "JAV" },
      { name: "ko-KR", value: "JAV" },
      { name: "zh-CN", value: "JAV" },
      { name: "zh-TW", value: "JAV" },
      { name: "es-MX", value: "JAV" },
      { name: "de-DE", value: "JAV" },
      { name: "fr-CA", value: "JAV" },
      { name: "ru-RU", value: "JAV" },
      { name: "pt-BR", value: "JAV" },
    ],
    styleImage: "https://cdn6.imgpog.com/jav.webp",
  },
  {
    id: "kidol", //"64b3f6232bb47734df5a21da",
    altId: ["64b3f6232bb47734df5a21da"],
    cfgU: 8.0,
    cfgM: 7.0,
    cfgL: 6.0,
    styleName: [
      { name: "en-US", value: "K-Idol" },
      { name: "ja-JP", value: "J-アイドル" },
      { name: "ko-KR", value: "K-아이돌" },
      { name: "zh-CN", value: "中日韩" },
      { name: "zh-TW", value: "中日韩" },
      { name: "es-MX", value: "K-Ídolo" },
      { name: "de-DE", value: "K-Idol" },
      { name: "fr-CA", value: "K-Idole" },
      { name: "ru-RU", value: "K-идол" },
      { name: "pt-BR", value: "K-Ídolo" },
    ],
    styleImage: "https://cdn6.imgpog.com/k-idol.webp",
  },
  {
    id: "3d", //"65797cb67a43c31ebe123632,6503c82215060ad3032def30",
    altId: ["65797cb67a43c31ebe123632"],
    cfgU: 7.5, // 8.0,
    cfgM: 6.5, // 7.0,
    cfgL: 5.5, // 6.0,
    styleName: [
      { name: "en-US", value: "Semi Realism" },
      { name: "ja-JP", value: "セミリアリズム" },
      { name: "ko-KR", value: "세미 리얼리즘" },
      { name: "zh-CN", value: "半真实风格" },
      { name: "zh-TW", value: "半真實風格" },
      { name: "es-MX", value: "Semi-Realismo" },
      { name: "de-DE", value: "Semi-Realismus" },
      { name: "fr-CA", value: "Semi-Réalisme" },
      { name: "ru-RU", value: "Полуреализм" },
      { name: "pt-BR", value: "Semi-Realismo" },
    ],
    styleImage: "https://cdn6.imgpog.com/semirealistic.webp",
  },
  {
    id: "anime", //"65797b3c7a43c31ebe123631,64c1ad95a126dc5fdb22a25d",
    altId: ["65797b3c7a43c31ebe123631", "64c1ad95a126dc5fdb22a25d"],
    cfgU: 8.0,
    cfgM: 6.5,
    cfgL: 5.0,
    styleName: [
      { name: "en-US", value: "Anime" },
      { name: "ja-JP", value: "アニメ" },
      { name: "ko-KR", value: "애니메이션" },
      { name: "zh-CN", value: "动画" },
      { name: "zh-TW", value: "動畫" },
      { name: "es-MX", value: "Anime" },
      { name: "de-DE", value: "Anime" },
      { name: "fr-CA", value: "Anime" },
      { name: "ru-RU", value: "Аниме" },
      { name: "pt-BR", value: "Anime" },
    ],
    styleImage: "https://cdn6.imgpog.com/anime.webp",
  },
  {
    id: "vibrant", //"65797d6f7a43c31ebe123633,6567f922fa831ab11fdaecc5",
    altId: ["65797d6f7a43c31ebe123633", "6567f922fa831ab11fdaecc5"],
    cfgU: 8.0,
    cfgM: 7.0,
    cfgL: 6.0,
    styleName: [
      { name: "en-US", value: "Webtoon" },
      { name: "ja-JP", value: "ウェブトゥーン" },
      { name: "ko-KR", value: "웹툰" },
      { name: "zh-CN", value: "网络漫画" },
      { name: "zh-TW", value: "網絡漫畫" },
      { name: "es-MX", value: "Webtoon" },
      { name: "de-DE", value: "Webtoon" },
      { name: "fr-CA", value: "Webtoon" },
      { name: "ru-RU", value: "Вебтун" },
      { name: "pt-BR", value: "Webtoon" },
    ],
    styleImage: "https://cdn6.imgpog.com/webtoon.webp",
  },
  {
    id: "photo",
    altId: ["6601f0c45f8d0d0b1ec339bf"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Realistic X" },
      { name: "ja-JP", value: "リアルな X" },
      { name: "ko-KR", value: "현실적인 X" },
      { name: "zh-CN", value: "现实风 X" },
      { name: "zh-TW", value: "現實風 X" },
      { name: "es-MX", value: "Realista X" },
      { name: "de-DE", value: "Realistisch X" },
      { name: "fr-CA", value: "Réaliste X" },
      { name: "ru-RU", value: "Реалистичный X" },
      { name: "pt-BR", value: "Realista X" },
    ],
    styleImage: "https://cdn6.imgpog.com/realistic.webp",
  },
  {
    id: "2.5d",
    altId: ["660b2fbf5f1174d66d287800"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "2.5D X" },
      { name: "ja-JP", value: "2.5D X" },
      { name: "ko-KR", value: "2.5D X" },
      { name: "zh-CN", value: "2.5D X" },
      { name: "zh-TW", value: "2.5D X" },
      { name: "es-MX", value: "2.5D X" },
      { name: "de-DE", value: "2.5D X" },
      { name: "fr-CA", value: "2.5D X" },
      { name: "ru-RU", value: "2.5D X" },
      { name: "pt-BR", value: "2.5D X" },
    ],
    styleImage: "https://cdn6.imgpog.com/twopointfived.webp",
  },
  {
    id: "sketch",
    altId: ["660b307d5f1174d66d28c184"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Sketch X" },
      { name: "ja-JP", value: "スケッチ X" },
      { name: "ko-KR", value: "스케치 X" },
      { name: "zh-CN", value: "素描 X" },
      { name: "zh-TW", value: "素描 X" },
      { name: "es-MX", value: "Boceto X" },
      { name: "de-DE", value: "Skizze X" },
      { name: "fr-CA", value: "Croquis X" },
      { name: "ru-RU", value: "Эскиз X" },
      { name: "pt-BR", value: "Esboço X" },
    ],
    styleImage: "https://cdn6.imgpog.com/lineArt.webp",
  },
  {
    id: "hentai",
    altId: ["660b31475f1174d66d297cc9"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Hentai X" },
      { name: "ja-JP", value: "エロアニメ X" },
      { name: "ko-KR", value: "에로 애니메이션 X" },
      { name: "zh-CN", value: "色情动漫 X" },
      { name: "zh-TW", value: "色情動漫 X" },
      { name: "es-MX", value: "Hentai X" },
      { name: "de-DE", value: "Hentai X" },
      { name: "fr-CA", value: "Hentai X" },
      { name: "ru-RU", value: "Эскиз X" },
      { name: "pt-BR", value: "Hentai X" },
    ],
    styleImage: "https://cdn6.imgpog.com/hentai.webp",
  },
  {
    id: "painting",
    altId: ["660b325b5f1174d66d29cd46"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Painting X" },
      { name: "ja-JP", value: "絵画 X" },
      { name: "ko-KR", value: "그림 X" },
      { name: "zh-CN", value: "绘画 X" },
      { name: "zh-TW", value: "繪畫 X" },
      { name: "es-MX", value: "Pintura X" },
      { name: "de-DE", value: "Malerei X" },
      { name: "fr-CA", value: "Peinture X" },
      { name: "ru-RU", value: "Живопись X" },
      { name: "pt-BR", value: "Pintura X" },
    ],
    styleImage: "https://cdn6.imgpog.com/oilPainting.webp",
  },
  {
    id: "surreal",
    altId: ["6647dbc569b831eea6ae79f1"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Surreal X" },
      { name: "ja-JP", value: "シュルレアリスムアート X" },
      { name: "ko-KR", value: "초현실주의 예술 X" },
      { name: "zh-CN", value: "超现实 X" },
      { name: "zh-TW", value: "超現實 X" },
      { name: "es-MX", value: "Surrealista X" },
      { name: "de-DE", value: "Surreale X" },
      { name: "fr-CA", value: "Surréaliste X" },
      { name: "ru-RU", value: "Сюрреалистическое искусство X" },
      { name: "pt-BR", value: "Surrealista X" },
    ],
    styleImage: "https://cdn6.imgpog.com/surreal.webp",
  },
  {
    id: "lineart",
    altId: ["6647dc2969b831eea6ae7e5c"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Line Art X" },
      { name: "ja-JP", value: "線画 X" },
      { name: "ko-KR", value: "선 예술 X" },
      { name: "zh-CN", value: "线条艺术 X" },
      { name: "zh-TW", value: "線條藝術 X" },
      { name: "es-MX", value: "Arte Lineal X" },
      { name: "de-DE", value: "Linienkunst X" },
      { name: "fr-CA", value: "Art Linéaire X" },
      { name: "ru-RU", value: "Линейное искусство X" },
      { name: "pt-BR", value: "Arte Linear X" },
    ],
    styleImage: "https://cdn6.imgpog.com/lineart.webp",
  },
  {
    id: "dream",
    altId: ["6671ccf90ca326d76eb4bd06"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Dream Like X" },
      { name: "ja-JP", value: "夢のような X" },
      { name: "ko-KR", value: "꿈같은 X" },
      { name: "zh-CN", value: "夢幻 X" },
      { name: "zh-TW", value: "梦幻 X" },
      { name: "es-MX", value: "Como en un Sueño X" },
      { name: "de-DE", value: "Traumhaft X" },
      { name: "fr-CA", value: "Comme dans un Rêve X" },
      { name: "ru-RU", value: "Как во сне X" },
      { name: "pt-BR", value: "Como um Sonho X" },
    ],
    styleImage: "https://cdn6.imgpog.com/dreamlike.webp",
  },
  {
    id: "webtoon",
    altId: ["6691b8955dae39ab9bf8792b"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Webtoon X" },
      { name: "ja-JP", value: "ウェブトゥーン X" },
      { name: "ko-KR", value: "웹툰 X" },
      { name: "zh-CN", value: "网络漫画 X" },
      { name: "zh-TW", value: "網絡漫畫 X" },
      { name: "es-MX", value: "Webtoon X" },
      { name: "de-DE", value: "Webtoon X" },
      { name: "fr-CA", value: "Webtoon X" },
      { name: "ru-RU", value: "Вебтун X" },
      { name: "pt-BR", value: "Webtoon X" },
    ],
    styleImage: "https://cdn6.imgpog.com/crpHentai.webp",
  },
  {
    id: "artoon",
    altId: ["669eb306c2ec6ae4aeff66b8"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Artoon X" },
      { name: "ja-JP", value: "アートゥーン X" },
      { name: "ko-KR", value: "아트툰 X" },
      { name: "zh-CN", value: "藝術卡通 X" },
      { name: "zh-TW", value: "艺术卡通 X" },
      { name: "es-MX", value: "Artoon X" },
      { name: "de-DE", value: "Artoon X" },
      { name: "fr-CA", value: "Artoon X" },
      { name: "ru-RU", value: "Арт-Тун X" },
      { name: "pt-BR", value: "Artoon X" },
    ],
    styleImage: "https://cdn6.imgpog.com/crphentaireal.webp",
  },
  // {
  //   id: "6503c7d715060ad3032def2f",
  //   styleName: [
  //     { name: "en-US", value: "Sandbox" },
  //     { name: "ja-JP", value: "サンドボックス" },
  //     { name: "ko-KR", value: "샌드박스" },
  //     { name: "zh-CN", value: "沙盒" },
  //     { name: "zh-TW", value: "沙盒" },
  //     { name: "es-MX", value: "Caja de arena" },
  //     { name: "de-DE", value: "Sandbox" },
  //     { name: "fr-CA", value: "Bac à sable" },
  //   ],
  //   styleImage:
  //     "https://cdn.discordapp.com/attachments/1117518726770733128/1152463576334880808/Intersection_1.png",
  // },
];

export const generators1 = [
  // {
  //   id: "realistic", //"657d4760e060719a80520ac6",
  //   altId: ["657d4760e060719a80520ac6"],
  //   cfgU: 8.0,
  //   cfgM: 7.0,
  //   cfgL: 6.0,
  //   styleName: [
  //     { name: "en-US", value: "Porno" },
  //     { name: "ja-JP", value: "ポルノ" },
  //     { name: "ko-KR", value: "야동" },
  //     { name: "zh-CN", value: "欧美" },
  //     { name: "zh-TW", value: "歐美" },
  //     { name: "es-MX", value: "Porno" },
  //     { name: "de-DE", value: "Porno" },
  //     { name: "fr-CA", value: "Porno" },
  //     { name: "ru-RU", value: "Порно" },
  //     { name: "pt-BR", value: "Porno" },
  //   ],
  //   styleImage: "https://cdn6.imgpog.com/realistic_gay.webp",
  // },
  {
    id: "photo",
    altId: ["6647d7f769b831eea6ae7155"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Realistic X" },
      { name: "ja-JP", value: "リアルな X" },
      { name: "ko-KR", value: "현실적인 X" },
      { name: "zh-CN", value: "现实风 X" },
      { name: "zh-TW", value: "現實風 X" },
      { name: "es-MX", value: "Realista X" },
      { name: "de-DE", value: "Realistisch X" },
      { name: "fr-CA", value: "Réaliste X" },
      { name: "ru-RU", value: "Реалистичный X" },
      { name: "pt-BR", value: "Realista X" },
    ],
    styleImage: "https://cdn6.imgpog.com/realisticg.webp",
  },
  {
    id: "anime", //"657d46e7e060719a80520ac5",
    altId: ["657d46e7e060719a80520ac5"],
    cfgU: 8.0,
    cfgM: 6.5,
    cfgL: 5.0,
    styleName: [
      { name: "en-US", value: "Anime" },
      { name: "ja-JP", value: "アニメ" },
      { name: "ko-KR", value: "애니메이션" },
      { name: "zh-CN", value: "动画" },
      { name: "zh-TW", value: "動畫" },
      { name: "es-MX", value: "Anime" },
      { name: "de-DE", value: "Anime" },
      { name: "fr-CA", value: "Anime" },
      { name: "ru-RU", value: "Аниме" },
      { name: "pt-BR", value: "Anime" },
    ],
    styleImage: "https://cdn6.imgpog.com/anime_gay.webp",
  },
  {
    id: "2.5d",
    altId: ["66170e86962e3776a4448c66"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "2.5D X" },
      { name: "ja-JP", value: "2.5D X" },
      { name: "ko-KR", value: "2.5D X" },
      { name: "zh-CN", value: "2.5D X" },
      { name: "zh-TW", value: "2.5D X" },
      { name: "es-MX", value: "2.5D X" },
      { name: "de-DE", value: "2.5D X" },
      { name: "fr-CA", value: "2.5D X" },
      { name: "ru-RU", value: "2.5D X" },
      { name: "pt-BR", value: "2.5D X" },
    ],
    styleImage: "https://cdn6.imgpog.com/twopointfivedg.webp",
  },
  {
    id: "sketch",
    altId: ["66170f1f962e3776a445d261"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Sketch X" },
      { name: "ja-JP", value: "スケッチ X" },
      { name: "ko-KR", value: "스케치 X" },
      { name: "zh-CN", value: "素描 X" },
      { name: "zh-TW", value: "素描 X" },
      { name: "es-MX", value: "Boceto X" },
      { name: "de-DE", value: "Skizze X" },
      { name: "fr-CA", value: "Croquis X" },
      { name: "ru-RU", value: "Эскиз X" },
      { name: "pt-BR", value: "Esboço X" },
    ],
    styleImage: "https://cdn6.imgpog.com/sketchg.webp",
  },
  {
    id: "hentai",
    altId: ["66170f71962e3776a4468865"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Hentai X" },
      { name: "ja-JP", value: "エロアニメ X" },
      { name: "ko-KR", value: "에로 애니메이션 X" },
      { name: "zh-CN", value: "色情动漫 X" },
      { name: "zh-TW", value: "色情動漫 X" },
      { name: "es-MX", value: "Hentai X" },
      { name: "de-DE", value: "Hentai X" },
      { name: "fr-CA", value: "Hentai X" },
      { name: "ru-RU", value: "Эскиз X" },
      { name: "pt-BR", value: "Hentai X" },
    ],
    styleImage: "https://cdn6.imgpog.com/hentaig.webp",
  },
  {
    id: "painting",
    altId: ["66170dc8962e3776a443186a"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Painting X" },
      { name: "ja-JP", value: "絵画 X" },
      { name: "ko-KR", value: "그림 X" },
      { name: "zh-CN", value: "绘画 X" },
      { name: "zh-TW", value: "繪畫 X" },
      { name: "es-MX", value: "Pintura X" },
      { name: "de-DE", value: "Malerei X" },
      { name: "fr-CA", value: "Peinture X" },
      { name: "ru-RU", value: "Живопись X" },
      { name: "pt-BR", value: "Pintura X" },
    ],
    styleImage: "https://cdn6.imgpog.com/paintingg.webp",
  },
  {
    id: "surreal",
    altId: ["6647d65b69b831eea6ae6d3d"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Surreal X" },
      { name: "ja-JP", value: "シュルレアリスムアート X" },
      { name: "ko-KR", value: "초현실주의 예술 X" },
      { name: "zh-CN", value: "超现实 X" },
      { name: "zh-TW", value: "超現實 X" },
      { name: "es-MX", value: "Surrealista X" },
      { name: "de-DE", value: "Surreale X" },
      { name: "fr-CA", value: "Surréaliste X" },
      { name: "ru-RU", value: "Сюрреалистическое искусство X" },
      { name: "pt-BR", value: "Surrealista X" },
    ],
    styleImage: "https://cdn6.imgpog.com/surrealg.webp",
  },
  {
    id: "lineart",
    altId: ["6647d43f69b831eea6ae6925"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Line Art X" },
      { name: "ja-JP", value: "線画 X" },
      { name: "ko-KR", value: "선 예술 X" },
      { name: "zh-CN", value: "线条艺术 X" },
      { name: "zh-TW", value: "線條藝術 X" },
      { name: "es-MX", value: "Arte Lineal X" },
      { name: "de-DE", value: "Linienkunst X" },
      { name: "fr-CA", value: "Art Linéaire X" },
      { name: "ru-RU", value: "Линейное искусство X" },
      { name: "pt-BR", value: "Arte Linear X" },
    ],
    styleImage: "https://cdn6.imgpog.com/lineartg.webp",
  },
  {
    id: "dream",
    altId: ["668829b1489e31fde34f151a"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Dream Like X" },
      { name: "ja-JP", value: "夢のような X" },
      { name: "ko-KR", value: "꿈같은 X" },
      { name: "zh-CN", value: "夢幻 X" },
      { name: "zh-TW", value: "梦幻 X" },
      { name: "es-MX", value: "Como en un Sueño X" },
      { name: "de-DE", value: "Traumhaft X" },
      { name: "fr-CA", value: "Comme dans un Rêve X" },
      { name: "ru-RU", value: "Как во сне X" },
      { name: "pt-BR", value: "Como um Sonho X" },
    ],
    styleImage: "https://cdn6.imgpog.com/dreamg.webp",
  },
  {
    id: "webtoon",
    altId: ["669eb450c2ec6ae4aeff66bc"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Webtoon X" },
      { name: "ja-JP", value: "ウェブトゥーン X" },
      { name: "ko-KR", value: "웹툰 X" },
      { name: "zh-CN", value: "网络漫画 X" },
      { name: "zh-TW", value: "網絡漫畫 X" },
      { name: "es-MX", value: "Webtoon X" },
      { name: "de-DE", value: "Webtoon X" },
      { name: "fr-CA", value: "Webtoon X" },
      { name: "ru-RU", value: "Вебтун X" },
      { name: "pt-BR", value: "Webtoon X" },
    ],
    styleImage: "https://cdn6.imgpog.com/crpHentaig.webp",
  },
  {
    id: "artoon",
    altId: ["66bd33a0dbf6059d5642bc09"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Artoon" },
      { name: "ja-JP", value: "アートゥーン" },
      { name: "ko-KR", value: "아트툰" },
      { name: "zh-CN", value: "藝術卡通" },
      { name: "zh-TW", value: "艺术卡通" },
      { name: "es-MX", value: "Artoon" },
      { name: "de-DE", value: "Artoon" },
      { name: "fr-CA", value: "Artoon" },
      { name: "ru-RU", value: "Арт-Тун" },
      { name: "pt-BR", value: "Artoon" },
    ],
    styleImage: "https://cdn6.imgpog.com/crgpartoon.webp",
  },
];

export const generators2 = [
  {
    id: "anime", //"64c1ad95a126dc5fdb22a25d",
    altId: ["64c1ad95a126dc5fdb22a25d"],
    cfgU: 8.0,
    cfgM: 6.5,
    cfgL: 5.0,
    styleName: [
      { name: "en-US", value: "Anime" },
      { name: "ja-JP", value: "アニメ" },
      { name: "ko-KR", value: "애니메이션" },
      { name: "zh-CN", value: "动画" },
      { name: "zh-TW", value: "動畫" },
      { name: "es-MX", value: "Anime" },
      { name: "de-DE", value: "Anime" },
      { name: "fr-CA", value: "Anime" },
      { name: "ru-RU", value: "Аниме" },
      { name: "pt-BR", value: "Anime" },
    ],
    styleImage: "https://cdn6.imgpog.com/anime.webp",
  },
  {
    id: "vibrant", //"6567f922fa831ab11fdaecc5",
    altId: ["6567f922fa831ab11fdaecc5"],
    cfgU: 7.5, // 8.0,
    cfgM: 6.5, // 7.0,
    cfgL: 5.5, // 6.0,
    styleName: [
      { name: "en-US", value: "Webtoon" },
      { name: "ja-JP", value: "ウェブトゥーン" },
      { name: "ko-KR", value: "웹툰" },
      { name: "zh-CN", value: "网络漫画" },
      { name: "zh-TW", value: "網絡漫畫" },
      { name: "es-MX", value: "Webtoon" },
      { name: "de-DE", value: "Webtoon" },
      { name: "fr-CA", value: "Webtoon" },
      { name: "ru-RU", value: "Вебтун" },
      { name: "pt-BR", value: "Webtoon" },
    ],
    styleImage: "https://cdn6.imgpog.com/webtoon.webp",
  },
  {
    id: "3d", //"6503c82215060ad3032def30",
    altId: ["6503c82215060ad3032def30"],
    cfgU: 8.0,
    cfgM: 7.0,
    cfgL: 6.0,
    styleName: [
      { name: "en-US", value: "Semi Realism" },
      { name: "ja-JP", value: "セミリアリズム" },
      { name: "ko-KR", value: "세미 리얼리즘" },
      { name: "zh-CN", value: "半真实风格" },
      { name: "zh-TW", value: "半真實風格" },
      { name: "es-MX", value: "Semi-Realismo" },
      { name: "de-DE", value: "Semi-Realismus" },
      { name: "fr-CA", value: "Semi-Réalisme" },
      { name: "ru-RU", value: "Полуреализм" },
      { name: "pt-BR", value: "Semi-Realismo" },
    ],
    styleImage: "https://cdn6.imgpog.com/semirealistic.webp",
  },
  {
    id: "3dcartoon",
    altId: [""],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "3D (Cartoon)" },
      { name: "ja-JP", value: "3D (カートゥーン)" },
      { name: "ko-KR", value: "3D (만화)" },
      { name: "zh-CN", value: "3D (卡通)" },
      { name: "zh-TW", value: "3D (卡通)" },
      { name: "es-MX", value: "3D (Dibujos Animados)" },
      { name: "de-DE", value: "3D (Zeichentrick)" },
      { name: "fr-CA", value: "3D (Dessin Animé)" },
      { name: "ru-RU", value: "3D (Мультфильм)" },
      { name: "pt-BR", value: "3D (Desenho Animado)" },
    ],
    styleImage: "https://cdn6.imgpog.com/3dcartoon.webp",
  },
  {
    id: "animation",
    altId: ["6685fa1c489e31fde33afca2"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "3D (Animation)" },
      { name: "ja-JP", value: "3D (アニメーション)" },
      { name: "ko-KR", value: "3D (애니메이션)" },
      { name: "zh-CN", value: " 3D (动画)" },
      { name: "zh-TW", value: "3D (動畫)" },
      { name: "es-MX", value: "3D (Animación)" },
      { name: "de-DE", value: "3D (Animation)" },
      { name: "fr-CA", value: "3D (Animation)" },
      { name: "ru-RU", value: "3D (Анимация)" },
      { name: "pt-BR", value: "3D (Animação)" },
    ],
    styleImage: "https://cdn6.imgpog.com/3danimation.webp",
  },
  {
    id: "2.5d",
    altId: ["66144a188cd3b055163dd24e"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "2.5D X" },
      { name: "ja-JP", value: "2.5D X" },
      { name: "ko-KR", value: "2.5D X" },
      { name: "zh-CN", value: "2.5D X" },
      { name: "zh-TW", value: "2.5D X" },
      { name: "es-MX", value: "2.5D X" },
      { name: "de-DE", value: "2.5D X" },
      { name: "fr-CA", value: "2.5D X" },
      { name: "ru-RU", value: "2.5D X" },
      { name: "pt-BR", value: "2.5D X" },
    ],
    styleImage: "https://cdn6.imgpog.com/twopointfived.webp",
  },
  {
    id: "sketch",
    altId: ["66144e058cd3b055163dd24f"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Sketch X" },
      { name: "ja-JP", value: "スケッチ X" },
      { name: "ko-KR", value: "스케치 X" },
      { name: "zh-CN", value: "素描 X" },
      { name: "zh-TW", value: "素描 X" },
      { name: "es-MX", value: "Boceto X" },
      { name: "de-DE", value: "Skizze X" },
      { name: "fr-CA", value: "Croquis X" },
      { name: "ru-RU", value: "Эскиз X" },
      { name: "pt-BR", value: "Esboço X" },
    ],
    styleImage: "https://cdn6.imgpog.com/lineArt.webp",
  },
  {
    id: "hentai",
    altId: ["66144e988cd3b055163dd250"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Hentai X" },
      { name: "ja-JP", value: "エロアニメ X" },
      { name: "ko-KR", value: "에로 애니메이션 X" },
      { name: "zh-CN", value: "色情动漫 X" },
      { name: "zh-TW", value: "色情動漫 X" },
      { name: "es-MX", value: "Hentai X" },
      { name: "de-DE", value: "Hentai X" },
      { name: "fr-CA", value: "Hentai X" },
      { name: "ru-RU", value: "Эскиз X" },
      { name: "pt-BR", value: "Hentai X" },
    ],
    styleImage: "https://cdn6.imgpog.com/hentai.webp",
  },
  {
    id: "painting",
    altId: ["66144f6a8cd3b055163dd251"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Painting X" },
      { name: "ja-JP", value: "絵画 X" },
      { name: "ko-KR", value: "그림 X" },
      { name: "zh-CN", value: "绘画 X" },
      { name: "zh-TW", value: "繪畫 X" },
      { name: "es-MX", value: "Pintura X" },
      { name: "de-DE", value: "Malerei X" },
      { name: "fr-CA", value: "Peinture X" },
      { name: "ru-RU", value: "Живопись X" },
      { name: "pt-BR", value: "Pintura X" },
    ],
    styleImage: "https://cdn6.imgpog.com/oilPainting.webp",
  },
  {
    id: "surreal",
    altId: ["664bb02fe9e45e07006ca4f6"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Surreal X" },
      { name: "ja-JP", value: "シュルレアリスムアート X" },
      { name: "ko-KR", value: "초현실주의 예술 X" },
      { name: "zh-CN", value: "超现实 X" },
      { name: "zh-TW", value: "超現實 X" },
      { name: "es-MX", value: "Surrealista X" },
      { name: "de-DE", value: "Surreale X" },
      { name: "fr-CA", value: "Surréaliste X" },
      { name: "ru-RU", value: "Сюрреалистическое искусство X" },
      { name: "pt-BR", value: "Surrealista X" },
    ],
    styleImage: "https://cdn6.imgpog.com/surreal.webp",
  },
  {
    id: "lineart",
    altId: ["664bafece9e45e07006ca4f5"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Line Art X" },
      { name: "ja-JP", value: "線画 X" },
      { name: "ko-KR", value: "선 예술 X" },
      { name: "zh-CN", value: "线条艺术 X" },
      { name: "zh-TW", value: "線條藝術 X" },
      { name: "es-MX", value: "Arte Lineal X" },
      { name: "de-DE", value: "Linienkunst X" },
      { name: "fr-CA", value: "Art Linéaire X" },
      { name: "ru-RU", value: "Линейное искусство X" },
      { name: "pt-BR", value: "Arte Linear X" },
    ],
    styleImage: "https://cdn6.imgpog.com/lineart.webp",
  },
  {
    id: "dream",
    altId: ["6671b8060ca326d76eb4b876"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Dream Like X" },
      { name: "ja-JP", value: "夢のような X" },
      { name: "ko-KR", value: "꿈같은 X" },
      { name: "zh-CN", value: "夢幻 X" },
      { name: "zh-TW", value: "梦幻 X" },
      { name: "es-MX", value: "Como en un Sueñ X" },
      { name: "de-DE", value: "Traumhaft X" },
      { name: "fr-CA", value: "Comme dans un Rêve X" },
      { name: "ru-RU", value: "Как во сне X" },
      { name: "pt-BR", value: "Como um Sonho X" },
    ],
    styleImage: "https://cdn6.imgpog.com/dreamlike.webp",
  },
  {
    id: "cartoon",
    altId: ["6671b7d90ca326d76eb4af92"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Cartoon" },
      { name: "ja-JP", value: "カートゥーン X" },
      { name: "ko-KR", value: "만화 X" },
      { name: "zh-CN", value: "卡通 X" },
      { name: "zh-TW", value: "卡通 X" },
      { name: "es-MX", value: "Dibujos Animados X" },
      { name: "de-DE", value: "Zeichentrick X" },
      { name: "fr-CA", value: "Dessin Animé X" },
      { name: "ru-RU", value: "Мультфильм X" },
      { name: "pt-BR", value: "Desenho Animado X" },
    ],
    styleImage: "https://cdn6.imgpog.com/cartoon.webp",
  },
  {
    id: "pastel",
    altId: ["66882a89489e31fde350e29c"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Hentai Pastel X" },
      { name: "ja-JP", value: "エロアニメパステル X" },
      { name: "ko-KR", value: "에로 애니메이션파스텔 X" },
      { name: "zh-CN", value: "粉彩色情动漫 X" },
      { name: "zh-TW", value: "粉彩色情動漫 X" },
      { name: "es-MX", value: "Hentai Pastel X" },
      { name: "de-DE", value: "Hentai Pastell X" },
      { name: "fr-CA", value: "Hentai Pastel X" },
      { name: "ru-RU", value: "Эскиз X" },
      { name: "pt-BR", value: "Hentai Pastel X" },
    ],
    styleImage: "https://cdn6.imgpog.com/pastel.webp",
  },
  {
    id: "webtoon",
    altId: ["6691b8f15dae39ab9bf87d11"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Webtoon X" },
      { name: "ja-JP", value: "ウェブトゥーン X" },
      { name: "ko-KR", value: "웹툰 X" },
      { name: "zh-CN", value: "网络漫画 X" },
      { name: "zh-TW", value: "網絡漫畫 X" },
      { name: "es-MX", value: "Webtoon X" },
      { name: "de-DE", value: "Webtoon X" },
      { name: "fr-CA", value: "Webtoon X" },
      { name: "ru-RU", value: "Вебтун X" },
      { name: "pt-BR", value: "Webtoon X" },
    ],
    styleImage: "https://cdn6.imgpog.com/crpHentai.webp",
  },
  {
    id: "classic",
    altId: ["669eb3e2c2ec6ae4aeff66ba"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Classic Anime X" },
      { name: "ja-JP", value: "クラシックアニメ X" },
      { name: "ko-KR", value: "빈티지 애니 X" },
      { name: "zh-CN", value: "經典動畫 X" },
      { name: "zh-TW", value: "经典动画 X" },
      { name: "es-MX", value: "Anime Clásico X" },
      { name: "de-DE", value: "Klassisches Anime X" },
      { name: "fr-CA", value: "Anime Classique X" },
      { name: "ru-RU", value: "Классическое аниме X" },
      { name: "pt-BR", value: "Anime Clássico X" },
    ],
    styleImage: "https://cdn6.imgpog.com/classicanime.webp",
  },
  {
    id: "artoon",
    altId: ["669eb34dc2ec6ae4aeff66b9"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Artoon X" },
      { name: "ja-JP", value: "アートゥーン X" },
      { name: "ko-KR", value: "아트툰 X" },
      { name: "zh-CN", value: "藝術卡通 X" },
      { name: "zh-TW", value: "艺术卡通 X" },
      { name: "es-MX", value: "Artoon X" },
      { name: "de-DE", value: "Artoon X" },
      { name: "fr-CA", value: "Artoon X" },
      { name: "ru-RU", value: "Арт-Тун X" },
      { name: "pt-BR", value: "Artoon X" },
    ],
    styleImage: "https://cdn6.imgpog.com/crphentaireal.webp",
  },
];

export const generators3 = [
  {
    id: "cartoon",
    altId: ["65c1c2ddbd811e4ea8345ee7"],
    cfgU: 8.0,
    cfgM: 7.0,
    cfgL: 6.0,
    styleName: [
      { name: "en-US", value: "Cartoon Furry" },
      { name: "ja-JP", value: "カートゥーンフェリー" },
      { name: "ko-KR", value: "만화 스타일 퍼리" },
      { name: "zh-CN", value: "卡通兽迷" },
      { name: "zh-TW", value: "卡通獸迷" },
      { name: "es-MX", value: "Furry de Estilo de Dibujos Animados" },
      { name: "de-DE", value: "Zeichentrick Furry" },
      { name: "fr-CA", value: "Furry de Style Cartoon" },
      { name: "ru-RU", value: "Мультяшный фурри" },
      { name: "pt-BR", value: "Furry de Desenho Animado" },
    ],
    styleImage: "https://cdn6.imgpog.com/cartoon_furry.webp",
  },
  {
    id: "animated",
    altId: ["65c135d6da20fc421e977d1f"],
    cfgU: 8.0,
    cfgM: 7.0,
    cfgL: 6.0,
    styleName: [
      { name: "en-US", value: "Animated Furry" },
      { name: "ja-JP", value: "アニメーションファーリー" },
      { name: "ko-KR", value: "애니메이션 퍼리" },
      { name: "zh-CN", value: "动画风兽迷" },
      { name: "zh-TW", value: "動畫風獸迷" },
      { name: "es-MX", value: "Furry Animado" },
      { name: "de-DE", value: "Animierter Furry" },
      { name: "fr-CA", value: "Furry Animé" },
      { name: "ru-RU", value: "Анимированный фурри" },
      { name: "pt-BR", value: "Furry Animado" },
    ],
    styleImage: "https://cdn6.imgpog.com/cartoonfurry.webp",
  },
  {
    id: "3d", //"65b42ceda12605d103d59b1a",
    altId: ["65b42ceda12605d103d59b1a"],
    cfgU: 8.0,
    cfgM: 7.0,
    cfgL: 6.0,
    styleName: [
      { name: "en-US", value: "3D Furry" },
      { name: "ja-JP", value: "3D ファーリー" },
      { name: "ko-KR", value: "3D 퍼리" },
      { name: "zh-CN", value: "3D 兽迷" },
      { name: "zh-TW", value: "3D 獸迷" },
      { name: "es-MX", value: "3D Furry" },
      { name: "de-DE", value: "3D Furry" },
      { name: "fr-CA", value: "3D Furry" },
      { name: "ru-RU", value: "3D фурри" },
      { name: "pt-BR", value: "Furry 3D" },
    ],
    styleImage: "https://cdn6.imgpog.com/3d_furry.webp",
  },
  {
    id: "realistic", //"65b42cd6a12605d103d599a5",
    altId: ["65b42cd6a12605d103d599a5"],
    cfgU: 8.0,
    cfgM: 6.5,
    cfgL: 5.0,
    styleName: [
      { name: "en-US", value: "Realistic Furry" },
      { name: "ja-JP", value: "リアルなファーリー" },
      { name: "ko-KR", value: "현실적인퍼리" },
      { name: "zh-CN", value: "现实风兽迷" },
      { name: "zh-TW", value: "現實風獸迷" },
      { name: "es-MX", value: "Furry Realista" },
      { name: "de-DE", value: "Realistisch Furry" },
      { name: "fr-CA", value: "Furry Réaliste" },
      { name: "ru-RU", value: "Реалистичный фурри" },
      { name: "pt-BR", value: "Furry Realistc" },
    ],
    styleImage: "https://cdn6.imgpog.com/realistic_furry.webp",
  },
  {
    id: "photo",
    altId: ["6647e1a669b831eea6ae9009"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Photorealistic Furry X" },
      { name: "ja-JP", value: "ファーリーフォトリアリスティック X" },
      { name: "ko-KR", value: "퍼리 사진 같은 X" },
      { name: "zh-CN", value: "兽迷写实 X" },
      { name: "zh-TW", value: "獸迷寫實 X" },
      { name: "es-MX", value: "Furry Fotorrealista X" },
      { name: "de-DE", value: "Furry Fotorealistisch X" },
      { name: "fr-CA", value: "Furry Photoréaliste X" },
      { name: "ru-RU", value: "Линейное Фотореалистичный X" },
      { name: "pt-BR", value: "Furry Fotorrealista X" },
    ],
    styleImage: "https://cdn6.imgpog.com/photorealisticfurry.webp",
  },
  {
    id: "hentai",
    altId: ["6615816f8335b2b1cd8defcf"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Furry Hentai X" },
      { name: "ja-JP", value: "ファーリーエロアニメ X" },
      { name: "ko-KR", value: "퍼리에로애니메이션 X" },
      { name: "zh-CN", value: "兽迷色情动漫 X" },
      { name: "zh-TW", value: "獸迷色情動漫 X" },
      { name: "es-MX", value: "Furry Hentai X" },
      { name: "de-DE", value: "Furry Hentai X" },
      { name: "fr-CA", value: "Furry Hentai X" },
      { name: "ru-RU", value: "Эскиз X" },
      { name: "pt-BR", value: "Furry Hentai X" },
    ],
    styleImage: "https://cdn6.imgpog.com/furryHentai.webp",
  },
  {
    id: "sketch",
    altId: ["661588038335b2b1cd8e4f83"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Furry Sketch X" },
      { name: "ja-JP", value: "ファーリースケッチ X" },
      { name: "ko-KR", value: "퍼리스케치 X" },
      { name: "zh-CN", value: "兽迷素描 X" },
      { name: "zh-TW", value: "獸迷素描 X" },
      { name: "es-MX", value: "Boceto Furry X" },
      { name: "de-DE", value: "Skizze Furry X" },
      { name: "fr-CA", value: "Croquis Furry X" },
      { name: "ru-RU", value: "Эскиз X" },
      { name: "pt-BR", value: "Esboço Furry X" },
    ],
    styleImage: "https://cdn6.imgpog.com/furrySketch.webp",
  },
  {
    id: "painting",
    altId: ["661598bf8335b2b1cd8f3b53"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Furry Painting X" },
      { name: "ja-JP", value: "ファーリー絵画 X" },
      { name: "ko-KR", value: "퍼리그림 X" },
      { name: "zh-CN", value: "绘画 X" },
      { name: "zh-TW", value: "繪畫 X" },
      { name: "es-MX", value: "Furry Pintura X" },
      { name: "de-DE", value: "Furry Malerei X" },
      { name: "fr-CA", value: "Furry Peinture X" },
      { name: "ru-RU", value: "Фурри Живопись X" },
      { name: "pt-BR", value: "Furry Pintura X" },
    ],
    styleImage: "https://cdn6.imgpog.com/furryPainting.webp",
  },
  {
    id: "surreal",
    altId: ["6647e2b569b831eea6ae9462"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Surreal Furry X" },
      { name: "ja-JP", value: "ファーリーシュルレアリスム X" },
      { name: "ko-KR", value: "퍼리초현실주의 X" },
      { name: "zh-CN", value: "超现实兽迷 X" },
      { name: "zh-TW", value: "超現實獸迷 X" },
      { name: "es-MX", value: "Furry Surrealista X" },
      { name: "de-DE", value: "Furry Surreale X" },
      { name: "fr-CA", value: "Furry Surréaliste X" },
      { name: "ru-RU", value: "Сюрреалистическое искусство X" },
      { name: "pt-BR", value: "Furry Surrealista X" },
    ],
    styleImage: "https://cdn6.imgpog.com/surrealfurry.webp",
  },
  {
    id: "lineart",
    altId: ["6647e3b469b831eea6ae98bb"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Furry Line Art X" },
      { name: "ja-JP", value: "ファーリー線画 X" },
      { name: "ko-KR", value: "퍼리 선 예술 X" },
      { name: "zh-CN", value: "兽迷线条艺术 X" },
      { name: "zh-TW", value: "獸迷線條藝術 X" },
      { name: "es-MX", value: "Furry Arte Lineal X" },
      { name: "de-DE", value: "Furry Linienkunst X" },
      { name: "fr-CA", value: "Furry Art Linéaire X" },
      { name: "ru-RU", value: "Линейное искусство X" },
      { name: "pt-BR", value: "Furry Arte Linear X" },
    ],
    styleImage: "https://cdn6.imgpog.com/lineartfurry.webp",
  },
  {
    id: "dream",
    altId: ["668828b3489e31fde34cdcf3"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Dream Like X" },
      { name: "ja-JP", value: "夢のような X" },
      { name: "ko-KR", value: "꿈같은 X" },
      { name: "zh-CN", value: "夢幻兽迷 X" },
      { name: "zh-TW", value: "梦幻獸迷 X" },
      { name: "es-MX", value: "Furry Sueño X" },
      { name: "de-DE", value: "Furry Traumhaft X" },
      { name: "fr-CA", value: "Furry Rêve X" },
      { name: "ru-RU", value: "Фурри сне X" },
      { name: "pt-BR", value: "Furry Sonho X" },
    ],
    styleImage: "https://cdn6.imgpog.com/dreamfurry.webp",
  },
  {
    id: "artoon",
    altId: ["66bd3377dbf6059d5642b9ab"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Artoon" },
      { name: "ja-JP", value: "アートゥーン" },
      { name: "ko-KR", value: "아트툰" },
      { name: "zh-CN", value: "藝術卡通" },
      { name: "zh-TW", value: "艺术卡通" },
      { name: "es-MX", value: "Artoon" },
      { name: "de-DE", value: "Artoon" },
      { name: "fr-CA", value: "Artoon" },
      { name: "ru-RU", value: "Арт-Тун" },
      { name: "pt-BR", value: "Artoon" },
    ],
    styleImage: "https://cdn6.imgpog.com/crfartoon.webp",
  },
];

export const generators4 = [
  {
    id: "kidol", //"65bd7dbdcfffe01185ca994a",
    altId: ["65bd7dbdcfffe01185ca994a"],
    cfgU: 8.0,
    cfgM: 7.0,
    cfgL: 6.0,
    styleName: [
      { name: "en-US", value: "K-Idol" },
      { name: "ja-JP", value: "J-アイドル" },
      { name: "ko-KR", value: "K-아이돌" },
      { name: "zh-CN", value: "中日韩" },
      { name: "zh-TW", value: "中日韩" },
      { name: "es-MX", value: "K-Ídolo" },
      { name: "de-DE", value: "K-Idol" },
      { name: "fr-CA", value: "K-Idole" },
      { name: "ru-RU", value: "K-идол" },
      { name: "pt-BR", value: "K-Ídolo" },
    ],
    styleImage: "https://cdn6.imgpog.com/k-idol.webp",
  },
  {
    id: "realisticasian",
    altId: ["65bd7de8cfffe01185ca994b"],
    cfgU: 8.0,
    cfgM: 6.5,
    cfgL: 5.0,
    styleName: [
      { name: "en-US", value: "JAV" },
      { name: "ja-JP", value: "JAV" },
      { name: "ko-KR", value: "JAV" },
      { name: "zh-CN", value: "JAV" },
      { name: "zh-TW", value: "JAV" },
      { name: "es-MX", value: "JAV" },
      { name: "de-DE", value: "JAV" },
      { name: "fr-CA", value: "JAV" },
      { name: "ru-RU", value: "JAV" },
      { name: "pt-BR", value: "JAV" },
    ],
    styleImage: "https://cdn6.imgpog.com/jav.webp",
  },
  {
    id: "jav",
    altId: ["6691b9275dae39ab9bf880f0"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "JAV X" },
      { name: "ja-JP", value: "JAV X" },
      { name: "ko-KR", value: "JAV X" },
      { name: "zh-CN", value: "JAV X" },
      { name: "zh-TW", value: "JAV X" },
      { name: "es-MX", value: "JAV X" },
      { name: "de-DE", value: "JAV X" },
      { name: "fr-CA", value: "JAV X" },
      { name: "ru-RU", value: "JAV X" },
      { name: "pt-BR", value: "JAV X" },
    ],
    styleImage: "https://cdn6.imgpog.com/crpJAV.webp",
  },
];

export const generators5 = [
  {
    id: "studio", //"65eb90291a7c3f630ac78951",
    altId: ["65eb90291a7c3f630ac78951"],
    cfgU: 8.0,
    cfgM: 7.0,
    cfgL: 6.0,
    styleName: [
      { name: "en-US", value: "Studio" },
      { name: "ja-JP", value: "スタジオ " },
      { name: "ko-KR", value: "스튜디오" },
      { name: "zh-CN", value: "专业" },
      { name: "zh-TW", value: "專業" },
      { name: "es-MX", value: "Studio" },
      { name: "de-DE", value: "Studio" },
      { name: "fr-CA", value: "Estudio" },
      { name: "ru-RU", value: "Студия" },
      { name: "pt-BR", value: "Estúdio" },
    ],
    styleImage: "https://cdn6.imgpog.com/realisticShemale.webp",
  },
  {
    id: "photo",
    altId: ["6671b5680ca326d76eb4aa01"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Realistic X" },
      { name: "ja-JP", value: "リアルな X" },
      { name: "ko-KR", value: "현실적인 X" },
      { name: "zh-CN", value: "现实风 X" },
      { name: "zh-TW", value: "現實風 X" },
      { name: "es-MX", value: "Realista X" },
      { name: "de-DE", value: "Realistisch X" },
      { name: "fr-CA", value: "Réaliste X" },
      { name: "ru-RU", value: "Реалистичный X" },
      { name: "pt-BR", value: "Realista X" },
    ],
    styleImage: "https://cdn6.imgpog.com/realistic.webp",
  },
  {
    id: "2.5d",
    altId: ["6671b35e0ca326d76eb4a443"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "2.5D X" },
      { name: "ja-JP", value: "2.5D X" },
      { name: "ko-KR", value: "2.5D X" },
      { name: "zh-CN", value: "2.5D X" },
      { name: "zh-TW", value: "2.5D X" },
      { name: "es-MX", value: "2.5D X" },
      { name: "de-DE", value: "2.5D X" },
      { name: "fr-CA", value: "2.5D X" },
      { name: "ru-RU", value: "2.5D X" },
      { name: "pt-BR", value: "2.5D X" },
    ],
    styleImage: "https://cdn6.imgpog.com/twopointfived.webp",
  },
  {
    id: "sketch",
    altId: ["661710f2962e3776a449f9cc"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Sketch X" },
      { name: "ja-JP", value: "スケッチ X" },
      { name: "ko-KR", value: "스케치 X" },
      { name: "zh-CN", value: "素描 X" },
      { name: "zh-TW", value: "素描 X" },
      { name: "es-MX", value: "Boceto X" },
      { name: "de-DE", value: "Skizze X" },
      { name: "fr-CA", value: "Croquis X" },
      { name: "ru-RU", value: "Эскиз X" },
      { name: "pt-BR", value: "Esboço X" },
    ],
    styleImage: "https://cdn6.imgpog.com/lineArt.webp",
  },
  {
    id: "hentai",
    altId: ["6617108c962e3776a44907c1"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Hentai X" },
      { name: "ja-JP", value: "エロアニメ X" },
      { name: "ko-KR", value: "에로 애니메이션 X" },
      { name: "zh-CN", value: "色情动漫 X" },
      { name: "zh-TW", value: "色情動漫 X" },
      { name: "es-MX", value: "Hentai X" },
      { name: "de-DE", value: "Hentai X" },
      { name: "fr-CA", value: "Hentai X" },
      { name: "ru-RU", value: "Эскиз X" },
      { name: "pt-BR", value: "Hentai X" },
    ],
    styleImage: "https://cdn6.imgpog.com/hentai.webp",
  },
  {
    id: "painting",
    altId: ["66171198962e3776a44bbbef"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Painting X" },
      { name: "ja-JP", value: "絵画 X" },
      { name: "ko-KR", value: "그림 X" },
      { name: "zh-CN", value: "绘画 X" },
      { name: "zh-TW", value: "繪畫 X" },
      { name: "es-MX", value: "Pintura X" },
      { name: "de-DE", value: "Malerei X" },
      { name: "fr-CA", value: "Peinture X" },
      { name: "ru-RU", value: "Живопись X" },
      { name: "pt-BR", value: "Pintura X" },
    ],
    styleImage: "https://cdn6.imgpog.com/oilPainting.webp",
  },
  {
    id: "surreal",
    altId: ["6647e09069b831eea6ae8733"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Surreal X" },
      { name: "ja-JP", value: "シュルレアリスムアート X" },
      { name: "ko-KR", value: "초현실주의 예술 X" },
      { name: "zh-CN", value: "超现实 X" },
      { name: "zh-TW", value: "超現實 X" },
      { name: "es-MX", value: "Surrealista X" },
      { name: "de-DE", value: "Surreale X" },
      { name: "fr-CA", value: "Surréaliste X" },
      { name: "ru-RU", value: "Сюрреалистическое искусство X" },
      { name: "pt-BR", value: "Surrealista X" },
    ],
    styleImage: "https://cdn6.imgpog.com/surreal.webp",
  },
  {
    id: "lineart",
    altId: ["6647e11d69b831eea6ae8b9e"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Line Art X" },
      { name: "ja-JP", value: "線画 X" },
      { name: "ko-KR", value: "선 예술 X" },
      { name: "zh-CN", value: "线条艺术 X" },
      { name: "zh-TW", value: "線條藝術 X" },
      { name: "es-MX", value: "Arte Lineal X" },
      { name: "de-DE", value: "Linienkunst X" },
      { name: "fr-CA", value: "Art Linéaire X" },
      { name: "ru-RU", value: "Линейное искусство X" },
      { name: "pt-BR", value: "Arte Linear X" },
    ],
    styleImage: "https://cdn6.imgpog.com/lineart.webp",
  },
  {
    id: "dream",
    altId: ["669eb42cc2ec6ae4aeff66bb"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Dream Like X" },
      { name: "ja-JP", value: "夢のような X" },
      { name: "ko-KR", value: "꿈같은 X" },
      { name: "zh-CN", value: "夢幻 X" },
      { name: "zh-TW", value: "梦幻 X" },
      { name: "es-MX", value: "Como en un Sueño X" },
      { name: "de-DE", value: "Traumhaft X" },
      { name: "fr-CA", value: "Comme dans un Rêve X" },
      { name: "ru-RU", value: "Как во сне X" },
      { name: "pt-BR", value: "Como um Sonho X" },
    ],
    styleImage: "https://cdn6.imgpog.com/dreamlike.webp",
  },
  {
    id: "webtoon",
    altId: ["669eb4b6c2ec6ae4aeff66bd"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Webtoon X" },
      { name: "ja-JP", value: "ウェブトゥーン X" },
      { name: "ko-KR", value: "웹툰 X" },
      { name: "zh-CN", value: "网络漫画 X" },
      { name: "zh-TW", value: "網絡漫畫 X" },
      { name: "es-MX", value: "Webtoon X" },
      { name: "de-DE", value: "Webtoon X" },
      { name: "fr-CA", value: "Webtoon X" },
      { name: "ru-RU", value: "Вебтун X" },
      { name: "pt-BR", value: "Webtoon X" },
    ],
    styleImage: "https://cdn6.imgpog.com/crpHentai.webp",
  },
  {
    id: "artoon",
    altId: ["66bd2d29e455b840b7078f5e"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "Artoon" },
      { name: "ja-JP", value: "アートゥーン" },
      { name: "ko-KR", value: "아트툰" },
      { name: "zh-CN", value: "藝術卡通" },
      { name: "zh-TW", value: "艺术卡通" },
      { name: "es-MX", value: "Artoon" },
      { name: "de-DE", value: "Artoon" },
      { name: "fr-CA", value: "Artoon" },
      { name: "ru-RU", value: "Арт-Тун" },
      { name: "pt-BR", value: "Artoon" },
    ],
    styleImage: "https://cdn6.imgpog.com/crphentaireal.webp",
  },
];

export const generatorsDev = [
  {
    id: "realistic", //"65036631adca1b7ac4084694",
    altId: ["65036631adca1b7ac4084694"],
    cfgU: 8.0,
    cfgM: 7.0,
    cfgL: 6.0,
    styleName: [
      { name: "en-US", value: "Porno" },
      { name: "ja-JP", value: "ポルノ" },
      { name: "ko-KR", value: "야동" },
      { name: "zh-CN", value: "欧美" },
      { name: "zh-TW", value: "歐美" },
      { name: "es-MX", value: "Porno" },
      { name: "de-DE", value: "Porno" },
      { name: "fr-CA", value: "Porno" },
      { name: "ru-RU", value: "Порно" },
      { name: "pt-BR", value: "Porno" },
    ],
    styleImage: "https://cdn6.imgpog.com/porno.webp",
  },
  {
    id: "realisticphotography",
    altId: ["65983e0d838a5c28fbb3418d"],
    cfgU: 7.0,
    cfgM: 6.0,
    cfgL: 5.0,
    styleName: [
      { name: "en-US", value: "Amateur" },
      { name: "ja-JP", value: "オリジナル" },
      { name: "ko-KR", value: "원창작" },
      { name: "zh-CN", value: "原创" },
      { name: "zh-TW", value: "原創" },
      { name: "es-MX", value: "Aficionado" },
      { name: "de-DE", value: "Amateur" },
      { name: "fr-CA", value: "Amateur" },
      { name: "ru-RU", value: "Любительский" },
      { name: "pt-BR", value: "Amador" },
    ],
    styleImage: "https://cdn6.imgpog.com/amateur.webp",
  },
  {
    id: "hyperrealism", //"64dc7ee1e59dc9c994281b08",
    altId: ["64dc7ee1e59dc9c994281b08"],
    cfgU: 12.0,
    cfgM: 7.0,
    cfgL: 5.0,
    styleName: [
      { name: "en-US", value: "Artistic" },
      { name: "ja-JP", value: "芸術" },
      { name: "ko-KR", value: "예술적인" },
      { name: "zh-CN", value: "艺术" },
      { name: "zh-TW", value: "藝術" },
      { name: "es-MX", value: "Artístico" },
      { name: "de-DE", value: "Künstlerisch" },
      { name: "fr-CA", value: "Artistique" },
      { name: "ru-RU", value: "Артистичный" },
      { name: "pt-BR", value: "Artístico" },
    ],
    styleImage: "https://cdn6.imgpog.com/artistic.webp",
  },
  {
    id: "realisticasian",
    altId: ["65983f13838a5c28fbb37836"],
    cfgU: 8.0,
    cfgM: 6.5,
    cfgL: 5.0,
    styleName: [
      { name: "en-US", value: "JAV" },
      { name: "ja-JP", value: "JAV" },
      { name: "ko-KR", value: "JAV" },
      { name: "zh-CN", value: "JAV" },
      { name: "zh-TW", value: "JAV" },
      { name: "es-MX", value: "JAV" },
      { name: "de-DE", value: "JAV" },
      { name: "fr-CA", value: "JAV" },
      { name: "ru-RU", value: "JAV" },
      { name: "pt-BR", value: "JAV" },
    ],
    styleImage: "https://cdn6.imgpog.com/jav.webp",
  },
  {
    id: "kidol", //"64b3f6232bb47734df5a21da",
    altId: ["64b3f6232bb47734df5a21da"],
    cfgU: 8.0,
    cfgM: 7.0,
    cfgL: 6.0,
    styleName: [
      { name: "en-US", value: "K-Idol" },
      { name: "ja-JP", value: "J-アイドル" },
      { name: "ko-KR", value: "K-아이돌" },
      { name: "zh-CN", value: "中日韩" },
      { name: "zh-TW", value: "中日韩" },
      { name: "es-MX", value: "K-Ídolo" },
      { name: "de-DE", value: "K-Idol" },
      { name: "fr-CA", value: "K-Idole" },
      { name: "ru-RU", value: "K-идол" },
      { name: "pt-BR", value: "K-Ídolo" },
    ],
    styleImage: "https://cdn6.imgpog.com/k-idol.webp",
  },
  {
    id: "3d", //"65797cb67a43c31ebe123632,6503c82215060ad3032def30",
    altId: ["65797cb67a43c31ebe123632", "6503c82215060ad3032def30"],
    cfgU: 8.0,
    cfgM: 7.0,
    cfgL: 6.0,
    styleName: [
      { name: "en-US", value: "3D" },
      { name: "ja-JP", value: "3D" },
      { name: "ko-KR", value: "3D" },
      { name: "zh-CN", value: "3D" },
      { name: "zh-TW", value: "3D" },
      { name: "es-MX", value: "3D" },
      { name: "de-DE", value: "3D" },
      { name: "fr-CA", value: "3D" },
      { name: "ru-RU", value: "3D" },
      { name: "pt-BR", value: "3D" },
    ],
    styleImage: "https://cdn6.imgpog.com/3d.webp",
  },
  {
    id: "anime", //"65797b3c7a43c31ebe123631,64c1ad95a126dc5fdb22a25d",
    altId: ["65797b3c7a43c31ebe123631", "64c1ad95a126dc5fdb22a25d"],
    cfgU: 8.0,
    cfgM: 6.5,
    cfgL: 5.0,
    styleName: [
      { name: "en-US", value: "Anime" },
      { name: "ja-JP", value: "アニメ" },
      { name: "ko-KR", value: "애니메이션" },
      { name: "zh-CN", value: "动画" },
      { name: "zh-TW", value: "動畫" },
      { name: "es-MX", value: "Anime" },
      { name: "de-DE", value: "Anime" },
      { name: "fr-CA", value: "Anime" },
      { name: "ru-RU", value: "Аниме" },
      { name: "pt-BR", value: "Anime" },
    ],
    styleImage: "https://cdn6.imgpog.com/anime.webp",
  },
  {
    id: "vibrant", //"65797d6f7a43c31ebe123633,6567f922fa831ab11fdaecc5",
    altId: ["65797d6f7a43c31ebe123633", "6567f922fa831ab11fdaecc5"],
    cfgU: 8.0,
    cfgM: 7.0,
    cfgL: 6.0,
    styleName: [
      { name: "en-US", value: "Webtoon" },
      { name: "ja-JP", value: "ウェブトゥーン" },
      { name: "ko-KR", value: "웹툰" },
      { name: "zh-CN", value: "网络漫画" },
      { name: "zh-TW", value: "網絡漫畫" },
      { name: "es-MX", value: "Webtoon" },
      { name: "de-DE", value: "Webtoon" },
      { name: "fr-CA", value: "Webtoon" },
      { name: "ru-RU", value: "Вебтун" },
      { name: "pt-BR", value: "Webtoon" },
    ],
    styleImage: "https://cdn6.imgpog.com/webtoon.webp",
  },
  {
    id: "photo",
    altId: ["6601f0c45f8d0d0b1ec339bf"],
    cfgU: 7.5,
    cfgM: 6.5,
    cfgL: 5.5,
    styleName: [
      { name: "en-US", value: "⟡ Realistic" },
      { name: "ja-JP", value: "⟡ リアルな" },
      { name: "ko-KR", value: "⟡ 현실적인" },
      { name: "zh-CN", value: "⟡ 现实风" },
      { name: "zh-TW", value: "⟡ 現實風" },
      { name: "es-MX", value: "⟡ Realista" },
      { name: "de-DE", value: "⟡ Realistisch" },
      { name: "fr-CA", value: "⟡ Réaliste" },
      { name: "ru-RU", value: "⟡ Реалистичный" },
      { name: "pt-BR", value: "⟡ Realista" },
    ],
    styleImage: "https://cdn6.imgpog.com/realistic.webp",
  },
  {
    id: "dev", //"65797d6f7a43c31ebe123633,6567f922fa831ab11fdaecc5",
    altId: ["65797d6f7a43c31ebe120000"],
    cfgU: 8.0,
    cfgM: 7.0,
    cfgL: 6.0,
    styleName: [
      { name: "en-US", value: "DEV" },
      { name: "ja-JP", value: "DEV" },
      { name: "ko-KR", value: "DEV" },
      { name: "zh-CN", value: "DEV" },
      { name: "zh-TW", value: "DEV" },
      { name: "es-MX", value: "DEV" },
      { name: "de-DE", value: "DEV" },
      { name: "fr-CA", value: "DEV" },
      { name: "ru-RU", value: "DEV" },
      { name: "pt-BR", value: "DEV" },
    ],
    styleImage: "https://cdn6.imgpog.com/webtoon.webp",
  },
];

export const getGenerators = () => {
  switch (process.env.NEXT_PUBLIC_BASE_URL) {
    case "https://www.8porn.ai":
      return generators0;
    case "https://www.createporn.com":
      return generators0;
    case "https://www.createaigayporn.com":
      return generators1;
    case "https://www.createhentai.com":
      return generators2;
    case "https://www.createaifurry.com":
      return generators3;
    case "https://www.createaiasian.com":
      return generators4;
    case "https://www.createaishemale.com":
      return generators5;
    default:
      return generators0; // Default case, can also be some default generators
  }
};

export const generators = getGenerators();

export const findStyleName = (locale: string, style: string) => {
  let foundGenerator = null;
  for (let gen of generators) {
    if (gen.id == style) {
      foundGenerator = gen;
      break;
    }
  }

  if (!foundGenerator) {
    return null;
  }

  for (let name of foundGenerator.styleName) {
    if (name.name == locale) {
      return name.value;
    }
  }
  return null;
};

export function findStyleNameByIdAndLocale({
  id,
  locale,
}: {
  id: string | string[];
  locale: string;
}) {
  const generators = getGenerators();

  let foundGenerator = null;

  // Handle both single string and array of strings for id
  if (Array.isArray(id)) {
    // Find the first generator where any id in the array matches
    foundGenerator = generators.find((gen) =>
      id.some(
        (singleId) =>
          gen.id === singleId || (gen.altId && gen.altId.includes(singleId)),
      ),
    );
  } else {
    // Find the generator with the given id or altId
    foundGenerator = generators.find(
      (gen) => gen.id === id || (gen.altId && gen.altId.includes(id)),
    );
  }

  if (!foundGenerator) {
    return null;
  }

  // Find the styleName with the given locale within the generator
  const style = foundGenerator.styleName.find((style) => style.name === locale);
  return style ? style.value : null;
}

// TODO: make this a map more efficient for lookup, generators can be static
export function findIdByAltId({ altId }: { altId: string }) {
  // console.log("Searching for altId:", altId);

  const generators = getGenerators();
  // console.log("Generators:", generators);

  const generator = generators.find(
    (gen) => gen.altId && gen.altId.includes(altId),
  );
  if (!generator) {
    // console.log('Generator not found for altId:', altId);
    return null;
  }

  // console.log('Found primary ID:', generator.id);
  return generator.id;
}

export const indexToLocaleKey: any = {
  0: "en-US",
  1: "ja-JP",
  2: "ko-KR",
  3: "zh-CN",
  4: "zh-TW",
  5: "es-MX",
  6: "de-DE",
  7: "fr-CA",
  8: "ru-RU",
  9: "pt-BR",
};
